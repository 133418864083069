import React from 'react';
import { Link } from 'gatsby';

const Logo = () => {
  return (
    <Link to="/">
      <div className="w-12 h-16 inline-flex items-center justify-center bg-gray-500 text-gray-100 hover:bg-gray-600">
        <p className="font-bold tracking-widest">YL</p>
      </div>
      <style jsx>{`
        div {
          transition: background-color 0.2s;
        }
      `}</style>
    </Link>
  );
};

export default Logo;
