import React from 'react';
import { Link } from 'gatsby';

export const NavItem = ({ href, children, external }) => (
  <>
    {external ? (
      <a
        className="ml-4 text-gray-600 nav-item font-medium border-b border-gray-200 hover:border-teal-400 hover:text-teal-400"
        target={external ? '_blank' : ''}
        href={href}
        rel="noopener noreferrer"
      >
        {children}
      </a>
    ) : (
      <Link
        className="ml-4 text-gray-600 nav-item font-medium border-b border-gray-200 hover:border-teal-400 hover:text-teal-400"
        to={href}
      >
        {children}
      </Link>
    )}
    <style jsx>{`
      nav-item {
        transition: border-color 0.2s, color 0.2s;
      }
    `}</style>
  </>
);

const Nav = () => {
  return (
    <div className="flex items-center mt-4 -ml-4">
      <NavItem href="https://linkedin.com/in/yongla" external>
        LinkedIn
      </NavItem>
      <NavItem href="https://github.com/yongla" external>
        GitHub
      </NavItem>
      <NavItem href="/contact">Contact</NavItem>
    </div>
  );
};

export default Nav;
