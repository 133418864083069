import React from 'react';
import PropTypes from 'prop-types';
import Logo from '../components/Logo';
import Nav from '../components/Nav';

import './layout.css';

const Layout = ({ children }) => {
  return (
    <>
      <main className="w-screen h-screen bg-gray-200 overflow-auto">
        <div className="max-w-2xl antialiased mx-auto md:mt-24 py-4 md:py-6 px-4 md:px-8 text-gray-800">
          <Logo />
          <Nav />
          {children}
        </div>
      </main>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
